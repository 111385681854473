<template>
  <div class="main index" v-if="!isLoading">
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(savePopup)">
        <div>
          <div class="columns is-multiline">
            <div class="column is-12">
              <h1 class="is-size-3"><b>Settings</b></h1>
            </div>
            <div class="column is-10">
              <nav class="breadcrumb" aria-label="breadcrumbs">
                <ul>
                  <li>
                    <h3 class="is-size-6">Setting</h3>
                  </li>
                  <li>
                    <router-link :to="{ name: `user` }">
                      <h3 class="is-size-6">User</h3>
                    </router-link>
                  </li>
                  <li>
                    <h3
                      class="is-size-6 breadcrumb-item"
                      v-if="$route.name != 'editUser'"
                    >
                      Create User
                    </h3>
                    <h3 class="is-size-6 breadcrumb-item" v-else>Edit User</h3>
                  </li>
                </ul>
              </nav>
            </div>
            <div class="column is-2">
              <b-field grouped position="is-right">
                <b-button type="is-hcc" native-type="submit" icon-left="save">
                  Save
                </b-button>
              </b-field>
            </div>
          </div>
          <div class="columns is-multiline">
            <div class="column is-12">
              <div class="box">
                <!-- For name -->
                <div class="columns is-12">
                  <div class="column is-2">
                    <b>Name</b>
                    <b-tooltip type="is-danger" label="This field is required">
                      <span class="required">*</span>
                    </b-tooltip>
                  </div>
                  <div class="column is-10">
                    <ValidationProvider
                      name="name"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <b-field :type="errors.length > 0 ? 'is-danger' : null">
                        <b-input v-model="user.name"></b-input>
                      </b-field>
                      <span class="required">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div>

                <!-- For email -->
                <div class="columns is-12">
                  <div class="column is-2">
                    <b>Email</b>
                    <b-tooltip type="is-danger" label="This field is required">
                      <span class="required">*</span>
                    </b-tooltip>
                  </div>
                  <div class="column is-10">
                    <ValidationProvider
                      name="email"
                      rules="required|email"
                      v-slot="{ errors }"
                    >
                      <b-field :type="errors.length > 0 ? 'is-danger' : null">
                        <b-input v-model="user.email" type="email"></b-input>
                      </b-field>
                      <span class="required">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div>

                <!-- For password -->
                <div v-if="$route.name != 'editUser'" class="columns is-12">
                  <div class="column is-2">
                    <b>Password</b>
                    <b-tooltip type="is-danger" label="This field is required">
                      <span class="required">*</span>
                    </b-tooltip>
                  </div>
                  <div class="column is-10">
                    <ValidationProvider
                      vid="pConf1"
                      name="password"
                      rules="required|password:pConf2"
                      v-slot="{ errors }"
                    >
                      <b-field :type="errors.length > 0 ? 'is-danger' : null">
                        <b-input
                          type="password"
                          v-model="user.password"
                          password-reveal
                        ></b-input>
                      </b-field>
                      <span class="required">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div>

                <!-- For password confirmation -->
                <div v-if="$route.name != 'editUser'" class="columns is-12">
                  <div class="column is-2">
                    <b>Password Confirmation</b>
                    <b-tooltip type="is-danger" label="This field is required">
                      <span class="required">*</span>
                    </b-tooltip>
                  </div>
                  <div class="column is-10">
                    <ValidationProvider
                      vid="pConf2"
                      name="confirm password"
                      rules="required|password:pConf1"
                      v-slot="{ errors }"
                    >
                      <b-field :type="errors.length > 0 ? 'is-danger' : null">
                        <b-input
                          type="password"
                          v-model="user.confirmation"
                          password-reveal
                        ></b-input>
                      </b-field>
                      <span class="required">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="$route.name == 'editUser'" class="column is-12">
              <b-field grouped position="is-right">
                <router-link
                  :to="{
                    path: `/user/change-password/${$route.params.userId}`,
                  }"
                >
                  <b-button type="is-hcc">Change Password</b-button>
                </router-link>
              </b-field>
            </div>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
export default {
  name: 'Register',
  data() {
    return {
      isLoading: true,
      user: {
        name: null,
        password: null,
        email: null,
        confirmation: null,
      },
    }
  },
  created() {
    if (this.$route.name == 'editUser') {
      this.fetchData()
    } else {
      this.isLoading = false
    }
  },
  methods: {
    async fetchData() {
      this.isLoading = true
      const response = await this.$store.dispatch(
        'getUser',
        this.$route.params.userId
      )
      this.user = response
      this.isLoading = false
    },
    submitCreateUser() {
      this.$store.dispatch('createUser', {
        user: this.user,
      })
    },

    // For save alert
    savePopup() {
      this.$buefy.dialog.confirm({
        title: 'Save Assessment',
        message: `Are you sure want to save this user?`,
        cancelText: 'No, cancel it!',
        confirmText: 'Yes, save it!',
        type: 'is-success',
        onConfirm: () => this.createUser(),
      })
    },

    // For save user
    createUser() {
      if (this.$route.name != 'editUser') {
        this.$store
          .dispatch('register', this.user)
          .then(response => {
            if (response.data.status == 406) {
              this.danger(response.data.message)
            } else {
              this.success('Successfully create new user')
              this.$router.push({ name: 'user' })
            }
          })
          .catch(() => {
            this.snackbarWarningFailedRegistration()
          })
      } else {
        this.$store
          .dispatch('updateUser', this.user)
          .then(() => {
            this.$router.push({ name: 'user' })
          })
          .catch(() => {
            this.snackbarWarningFailedRegistration()
          })
      }
    },
    success(message) {
      this.$buefy.toast.open({
        message: message,
        type: 'is-success',
      })
    },
    danger(message) {
      this.$buefy.toast.open({
        message: message,
        type: 'is-danger',
      })
    },
  },
}
</script>
